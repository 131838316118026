<template>
  <div class="ec-container">
    <div class="ec-container px-160 txt-grey-900 pb-8">
      <BreadcrumbCpn :breadcrumb="breadcrumb" />

      <div class="row">
        <div class="col-md-8">
          <div class="bg-white p-3 radius-10 h-full">
            <div
              class="flex items-center justify-between fs-20 fw-500 border-b border-solid border-t-0 border-r-0 border-l-0 border-gray-200"
            >
              <div
                class="inline-block border-b-4 border-solid border-t-0 border-r-0 border-l-0 border-pri-900"
              >
                <span class="txt-grey-900">
                  {{
                  $t("shopping_cart.lbl_shopping_cart")
                  }}
                </span>

                <span class="txt-pri ml-2">
                  {{
                  $shoppingCart ? `(${$shoppingCart.length})` : "(0)"
                  }}
                </span>
              </div>
              <div v-if="$shoppingCart && $shoppingCart.length">
                <div
                  class="flex items-center justify-end text-gray-400 hover:text-red-500 transition-all duration-300 cursor-pointer"
                  @click="appUtils.removeAllCartData()"
                >
                  <div class="mr-1 leading-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.8499 9.14062L18.1999 19.2106C18.0899 20.7806 17.9999 22.0006 15.2099 22.0006H8.7899C5.9999 22.0006 5.9099 20.7806 5.7999 19.2106L5.1499 9.14062"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3301 16.5H13.6601"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 12.5H14.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <p class="fs-16 fw-400 leading-none mb-0">{{ $t("shopping_cart.lbl_remove_all") }}</p>
                </div>
              </div>
            </div>

            <ShoppingCartItem v-if="$shoppingCart && $shoppingCart.length" :products="$shoppingCart" />
            <div v-else class="ec-body-list d-flex justify-center h-full">
              <div class="my-auto text-center py-3">
                <div class="inline-block mb-3 w-32 h-32">
                  <img src="../../../public/images/Page/empty.png" class="img-fluid mb-3" alt />
                </div>
                <div class="text-center fs-20">
                  <span>{{ $t("shopping_cart.lbl_empty_cart") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="bg-white p-3 radius-10 mb-3" v-if="listVoucher && listVoucher.length">
            <p
              class="fs-20 fw-500 border-b border-solid border-t-0 border-r-0 border-l-0 border-gray-200"
            >
              <span
                class="txt-grey-900 inline-block border-b-4 border-solid border-t-0 border-r-0 border-l-0 border-pri-900"
              >Chọn mã giảm giá</span>
            </p>
            <el-radio-group v-model="voucher_active">
              <el-radio
                :label="item.voucher_code_id"
                v-for="item in listVoucher"
                :key="item.voucher_code_id"
              >
                <div class="voucher-wrapper mb-2">
                  <div class="org-logo">
                    <img src="/logo.png" alt />
                  </div>
                  <div class="voucher-info w-100">
                    <div class="flex items-center justify-between w-100">
                      <p class="mb-1 font-semibold">Mã giảm giá HODO</p>
                      <div class="voucher-value mb-1">Giảm {{ item.voucher_value }}</div>
                    </div>
                  </div>
                </div>
              </el-radio>
            </el-radio-group>
            <p class="not-use-voucher">
              hoặc
              <span
                class="ml-1 font-medium"
                @click="handleNotUseVoucher"
              >Không sử dụng mã giảm giá</span>
            </p>
          </div>
          <div class="bg-white p-3 radius-10 mb-3">
            <OrderAmount>
              <div class="mb-2" v-loading="loading_validate" v-if="voucherSelected">
                <div class="flex items-center justify-between">
                  <p>Giảm giá:</p>
                  <p>{{ formatPrice(voucherInfo.voucher_discount_price, 'VNĐ') }}</p>
                </div>
                <div class="flex items-center justify-between">
                  <p class="font-semibold fs-20">Tổng thanh toán:</p>
                  <p
                    class="font-semibold fs-20"
                    style="color: #476dd6"
                  >{{ formatPrice(totalAmount - voucherInfo.voucher_discount_price, 'VNĐ') }}</p>
                </div>
              </div>
              <div class="flex justify-content-end" v-if="$shoppingCart && $shoppingCart.length">
                <button
                  class="w-100 btn bg-pri text-white"
                  @click="handleConfirmPay"
                >{{ $t("shopping_cart.lbl_billing_confirmation") }}</button>
              </div>
            </OrderAmount>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { v4 as uuidv4 } from 'uuid'
import appUtils from '../../utils/appUtils'
import ShoppingCartItem from '../../components/Ecom/ShoppingCartItem.vue'
import BreadcrumbCpn from '../../components/Layout/BreadcrumbCpn.vue'
import OrderAmount from '../../components/Ecom/OrderAmount.vue'
import { mapGetters, mapState } from 'vuex'
// import { ORDERS_STATUS } from '../../utils/constant'

export default {
  name: 'ShoppingCart',
  components: { ShoppingCartItem, BreadcrumbCpn, OrderAmount },
  data () {
    return {
      appUtils,
      breadcrumb: [
        {
          text: 'Quay lại',
          to: {
            name: 'Ecommerce'
          }
        },
        {
          text: 'Sản phẩm/DV đã yêu cầu'
        }
      ],
      voucher_active: '',
      loading_validate: false,
      voucherInfo: {}
    }
  },
  computed: {
    amount () {
      return this.$shoppingCart.reduce(
        (accum, item) => accum + item.default_price * item.count,
        0
      )
    },
    ...mapGetters('ecommerce', ['voucherSelected']),
    ...mapState('ecommerce', ['voucher_code', 'listVoucher']),
    totalAmount () {
      return this.$shoppingCart
        ?.map(elm => elm.total_amount)
        ?.reduce((a, b) => a + b)
    }
  },
  async mounted () {
    if (this.voucherSelected) {
      this.getVoucherCode()
      this.voucher_active = this.voucherSelected
    }
    await this.$store.dispatch('ecommerce/getListVoucher', {
      user_id: this.$user?.id
    })
  },
  watch: {
    voucher_active: {
      async handler (value) {
        if (value) {
          this.$store.commit('ecommerce/SET_VOUCHER_SELECTED', value)
          await this.getVoucherCode()
          this.validateVoucher()
        } else {
          this.$store.commit(
            'ecommerce/SET_VOUCHER_SELECTED',
            this.voucher_active
          )
          this.$store.commit('ecommerce/SET_VOUCHER_CODE', {})
          this.$store.commit('ecommerce/SET_VOUCHER_INFO', {})
        }
      }
    },
    $shoppingCart () {
      this.voucher_active = ''
    }
  },
  methods: {
    async handleConfirmPay () {
      if (!this.$user) {
        this.$targetRoute = '/#/shopping-cart'
        this.$router.push({ name: 'Login' })
        return false
      }

      try {
        this.$router
          .push({
            name: 'OrderConfirm'
            // params: {
            //   id: this.oderId
            // }
          })
          .catch(_ => {})

        // const params = {
        //   package_id: 2,
        //   combo_id: 8,
        //   months: 12,
        //   voucher_code: '',
        //   description: ''
        // }
        // const res = await this.$rf
        //   .getRequest('AuthRequest')
        //   .postPackageOrder(params)

        // if (res.data) {
        //   this.oderId = res.data.id

        //   const invoices = appUtils.getInvoices(this.$user.id)

        //   invoices.push({
        //     id: uuidv4(),
        //     order_id: Number(res.data.id),
        //     products: [...this.$shoppingCart],
        //     status: ORDERS_STATUS.WAITING_CONFIRM,
        //     created_at: Date.now()
        //   })
        //   appUtils.setInvoices(invoices, this.$user.id)

        //   this.$router
        //     .push({
        //       name: 'OrderConfirm',
        //       params: {
        //         id: this.oderId
        //       }
        //     })
        //     .catch(_ => {})
        // }
      } catch (error) {
        console.log(error)
      }
    },
    async getVoucherCode () {
      try {
        await this.$store.dispatch('ecommerce/getVoucherCode', {
          voucher_envelop_id: this.listVoucher?.find(
            elm => elm.voucher_code_id === this.voucherSelected
          )?.id
        })
      } catch (error) {
        console.log(error)
      }
    },
    async validateVoucher () {
      try {
        const listProductVariants = this.$shoppingCart?.map(elm => {
          return {
            product_variant_id: elm.variant_id,
            qty: elm.count
          }
        })
        this.loading_validate = true
        const res = await this.$rf.getRequest('AuthRequest').validateVoucher({
          voucher_code: this.voucher_code?.code,
          product_variant_ids: listProductVariants
        })
        this.voucherInfo = res?.data
        this.$store.commit('ecommerce/SET_VOUCHER_INFO', this.voucherInfo)
      } catch (error) {
        console.log(error)
      } finally {
        this.loading_validate = false
      }
    },
    formatPrice (number, currency) {
      return appUtils.numberFormat(number) + ` ${currency}`
    },
    handleNotUseVoucher () {
      this.voucher_active = ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ec-container {
    background: rgba(247, 247, 249, 1);
    padding-top: 32px;
  }
  .btn-apply-vc {
    background: rgba(238, 97, 63, 1);
    width: 110px;
    border-radius: 4px;
    padding: 8px;
    border: 0;
    color: white;
    &:disabled {
      background: rgba(238, 97, 63, 0.5);
    }
  }
  .min-h-96 {
    min-height: 24rem /* 384px */;
  }
  .voucher-wrapper {
    cursor: pointer;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    gap: 12px;
    background: #fff;

    .org-logo {
      padding-right: 12px;
      border-right: 1px solid #dbdbdb;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .voucher-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .voucher-value {
        padding: 4px 8px;
        background-image: linear-gradient(180deg, #5c90dd, #1509b5);
        color: #fff;
        font-weight: 600;
        width: fit-content;
        border-radius: 4px;
        font-size: 16px;
        line-height: 24px;
      }
      .button {
        padding: 4px 8px;
        border-radius: 4px;
        background: #20419b;
        color: #fff;
        width: fit-content;
        transition: all ease-in-out 0.2s;
        &:hover {
          // opacity: 0.8;
          cursor: pointer;
          transform: scale(1.05);
        }
      }
    }
  }
  .el-radio-group {
    width: 100%;
    .el-radio {
      margin-right: 0;
      display: flex;
      gap: 12px;
      align-items: center;
      .el-radio__label {
        width: 100%;
        padding-left: 0;
      }
    }
  }
  .not-use-voucher {
    display: flex;
    justify-content: end;
    span:hover {
      color: #476dd6;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
